import "./App.css";
import Ladingpage from "./Ladingpage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SmallBusinessSolutions from "./pages/SmallBusinessSolutions";
import Enterprisesolutions from "./pages/home/Enterprisesolutions";
import Footer from "./components/Footer";
import BlogPost from "./components/blog/BlogPost";
import Blog from "./components/blog/Blog";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" caseSensitive={false} element={<Ladingpage />} />
          <Route
            path="/SmallBusinessSolutions"
            element={<SmallBusinessSolutions />}
          />
          <Route
            path="/Enterprisesolutions"
            element={<Enterprisesolutions />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
