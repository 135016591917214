import { useParams, useNavigate, Link } from "react-router-dom";
import { blogPosts } from "./blogData";
import { format } from "date-fns";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useEffect } from "react";
import { ArrowLeft, Home } from "lucide-react";
import "./BlogPost.css";

const BlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = blogPosts.find((post) => post.id === id);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (!post) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
        <div className="container mx-auto px-6">
          <h1 className="text-4xl font-bold text-white">Post not found</h1>
          <button
            onClick={() => navigate("/blog")}
            className="mt-4 flex items-center text-blue-400 hover:text-blue-300 transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Blog
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
      <article className="container mx-auto px-6 max-w-4xl">
        <div className="flex items-center gap-4 mb-8 fade-in">
          <button
            onClick={() => navigate("/blog")}
            className="flex items-center text-blue-400 hover:text-blue-300 transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Blog
          </button>
        </div>

        <div className="relative rounded-xl overflow-hidden mb-8 fade-in">
          <img
            src={post.image}
            alt={post.title}
            className="w-full h-[400px] object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent" />
        </div>

        <div className="flex items-center justify-between mb-6 fade-in">
          <div className="text-sm text-gray-400">
            {format(new Date(post.date), "MMMM dd, yyyy")}
          </div>
          <span className="px-4 py-2 bg-blue-500/20 text-blue-300 rounded-full text-sm">
            {post.category}
          </span>
        </div>

        <h1 className="text-4xl font-bold text-white mb-6 fade-in">
          {post.title}
        </h1>

        <div className="prose prose-lg prose-invert max-w-none">
          {post.content.map((section, index) => (
            <div
              key={index}
              className="mb-6 content-section"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              {section.type === "paragraph" && (
                <p className="text-gray-300 leading-relaxed">
                  {section.content}
                </p>
              )}
              {section.type === "heading" && (
                <h2 className="text-2xl font-bold text-white mt-8 mb-4">
                  {section.content}
                </h2>
              )}
              {section.type === "list" && (
                <ul className="list-disc list-inside space-y-2 text-gray-300">
                  {section.items?.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
              )}
              {section.type === "code" && (
                <div className="rounded-lg overflow-hidden my-6">
                  <SyntaxHighlighter
                    language={section.language}
                    style={vscDarkPlus}
                    showLineNumbers
                    customStyle={{
                      margin: 0,
                      padding: "1.5rem",
                      fontSize: "0.9rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    {section.content?.trim() || ""}
                  </SyntaxHighlighter>
                </div>
              )}
            </div>
          ))}
        </div>
      </article>
    </div>
  );
};

export default BlogPost;
