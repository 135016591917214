import React, { useEffect, useRef, useState, FormEvent } from "react";
import emailjs from "@emailjs/browser";
import {
  Shield,
  Cloud,
  ChevronRight,
  CheckCircle2,
  Award,
  Code,
  Send,
  X,
  Building2,
  Globe2,
  LineChart,
  Users,
  ExternalLink,
} from "lucide-react";

const services = [
  {
    icon: Globe2,
    title: "Global Infrastructure",
    description:
      "Worldwide network infrastructure with high-availability zones and enterprise-grade reliability across continents.",
    fullDescription:
      "Our Global Infrastructure provides enterprise organizations with a resilient, high-performance network spanning multiple continents. With strategically positioned data centers in key global markets, we ensure low-latency connections, geographic redundancy, and compliance with regional data sovereignty requirements. Our infrastructure is built on a carrier-grade backbone with multiple tier-1 provider connections, ensuring 99.999% uptime SLAs and seamless failover capabilities.",
    image:
      "https://images.unsplash.com/photo-1473186578172-c141e6798cf4?auto=format&fit=crop&q=80",
    features: [
      "Global network of 50+ data centers across 6 continents",
      "Multi-region high-availability architecture",
      "Automated geographic failover and disaster recovery",
      "Regional data sovereignty compliance",
      "Enterprise-grade SLAs with 99.999% uptime guarantee",
    ],
  },
  {
    icon: Shield,
    title: "Advanced Security",
    description:
      "Military-grade security protocols, zero-trust architecture, and AI-powered threat detection systems.",
    fullDescription:
      "Our Advanced Security solutions implement defense-in-depth strategies with multiple layers of protection for your enterprise assets. Built on zero-trust principles, our security framework continuously validates every access request regardless of source. We employ AI-powered threat intelligence systems that analyze billions of security events daily to identify and neutralize emerging threats before they impact your operations.",
    image:
      "https://images.unsplash.com/photo-1563986768609-322da13575f3?auto=format&fit=crop&q=80",
    features: [
      "Zero-trust security architecture with continuous verification",
      "AI-powered threat detection and response",
      "Enterprise-grade encryption for data at rest and in transit",
      "Advanced DDoS protection with 10+ Tbps mitigation capacity",
      "Compliance with SOC 2, ISO 27001, GDPR, HIPAA, and PCI DSS",
    ],
  },
  {
    icon: Cloud,
    title: "Enterprise Cloud",
    description:
      "Multi-cloud orchestration, hybrid solutions, and cloud-native transformation with seamless integration.",
    fullDescription:
      "Our Enterprise Cloud platform provides a unified management layer for multi-cloud and hybrid environments, allowing seamless workload migration and consistent governance across your entire infrastructure. We specialize in enterprise-scale cloud transformations, helping organizations modernize legacy systems while maintaining business continuity. Our cloud solutions are designed for mission-critical workloads with advanced performance optimization and cost management capabilities.",
    image:
      "https://images.unsplash.com/photo-1544197150-b99a580bb7a8?auto=format&fit=crop&q=80",
    features: [
      "Unified multi-cloud management platform",
      "Hybrid cloud integration with on-premises systems",
      "Cloud-native application development and modernization",
      "Enterprise workload optimization and auto-scaling",
      "Comprehensive cloud cost management and optimization",
    ],
  },
  {
    icon: LineChart,
    title: "Business Intelligence",
    description:
      "Advanced analytics, real-time reporting, and AI-driven insights for data-driven decision making.",
    fullDescription:
      "Our Business Intelligence solutions transform enterprise data into actionable insights through advanced analytics and visualization capabilities. We implement real-time data processing pipelines that connect disparate data sources across your organization, creating a unified view of business operations. Our AI-driven analytics identify patterns, anomalies, and opportunities that would otherwise remain hidden, enabling truly data-driven decision making at all levels of your organization.",
    image:
      "https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80",
    features: [
      "Enterprise data warehouse and lake house architectures",
      "Real-time analytics dashboards and reporting",
      "Predictive analytics and machine learning models",
      "Natural language processing for unstructured data analysis",
      "Custom KPI tracking and business performance monitoring",
    ],
  },
  {
    icon: Users,
    title: "Managed Services",
    description:
      "24/7 enterprise support, dedicated account teams, and comprehensive IT management solutions.",
    fullDescription:
      "Our Managed Services provide comprehensive IT operations support for enterprise organizations, allowing your team to focus on strategic initiatives rather than day-to-day management. We offer 24/7 monitoring and support with dedicated technical account managers who understand your business requirements. Our proactive maintenance and optimization services ensure your infrastructure operates at peak efficiency while continuously evolving to meet changing business needs.",
    image:
      "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80",
    features: [
      "24/7/365 monitoring and incident response",
      "Dedicated technical account management",
      "Proactive maintenance and patch management",
      "Capacity planning and infrastructure optimization",
      "Comprehensive SLA-backed support with 15-minute response time",
    ],
  },
  {
    icon: Code,
    title: "Digital Transformation",
    description:
      "End-to-end digital transformation strategies, legacy modernization, and innovation consulting.",
    fullDescription:
      "Our Digital Transformation practice helps enterprises navigate complex technology transitions with minimal disruption to ongoing operations. We specialize in modernizing legacy systems through incremental approaches that manage risk while delivering continuous business value. Our transformation frameworks incorporate change management, skills development, and organizational alignment to ensure technology investments deliver their full potential across your enterprise.",
    image:
      "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?auto=format&fit=crop&q=80",
    features: [
      "Enterprise architecture assessment and roadmap development",
      "Legacy system modernization and migration",
      "Agile transformation and DevOps implementation",
      "Digital workplace and collaboration solutions",
      "Innovation labs and emerging technology integration",
    ],
  },
];

const benefits = [
  {
    icon: Building2,
    title: "Enterprise Scale",
    description:
      "Infrastructure designed to scale with your global enterprise operations.",
  },
  {
    icon: Shield,
    title: "Zero-Trust Security",
    description:
      "Comprehensive security framework with continuous verification and monitoring.",
  },
  {
    icon: Award,
    title: "Industry Leadership",
    description:
      "Recognized industry leader with Fortune 500 partnerships and certifications.",
  },
];

const features = [
  "Global Infrastructure Management",
  "Zero-Trust Security Architecture",
  "24/7 Enterprise Support",
  "Multi-Cloud Orchestration",
  "Digital Transformation Strategy",
  "Advanced Analytics & BI",
];

const enterpriseStats = [
  { value: "1000+", label: "Enterprise Clients" },
  { value: "99.999%", label: "Service Availability" },
  { value: "24/7/365", label: "Global Support" },
];

function EnterpriseSolutions() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<
    (typeof services)[0] | null
  >(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
  const [selectedTopic, setSelectedTopic] = useState("");

  const handleConsultation = () => {
    setFormData((prev) => ({
      ...prev,
      message:
        "I'm interested in scheduling an enterprise consultation to discuss our global IT needs.",
    }));
    setSelectedTopic("Enterprise Consultation");
    setIsModalOpen(true);
  };

  const handleSolutions = () => {
    setFormData((prev) => ({
      ...prev,
      message:
        "I'd like to learn more about your enterprise solutions and global capabilities.",
    }));
    setSelectedTopic("Enterprise Solutions");
    setIsModalOpen(true);
  };

  const handleGetStarted = () => {
    setSelectedTopic("General Inquiry");
    setIsModalOpen(true);
  };

  // Updated submit handler with EmailJS
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: "", message: "" });

    try {
      const result = await emailjs.sendForm(
        "service_cs9pleq",
        "template_uevc4mr",
        formRef.current!,
        "qeF8ipKt8al_tV16A"
      );

      if (result.text === "OK") {
        setSubmitStatus({
          type: "success",
          message: "Message sent successfully! We will contact you soon.",
        });
        formRef.current?.reset();
        setSelectedTopic("");

        // Reset form data after successful submission
        setFormData({ name: "", email: "", company: "", message: "" });

        // Optional: Close the modal after a delay
        setTimeout(() => {
          setIsModalOpen(false);
          setSubmitStatus({ type: "", message: "" });
        }, 3000);
      }
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to send message. Please try again.",
      });
      console.error("EmailJS Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openServiceModal = (service: (typeof services)[0]) => {
    setSelectedService(service);
    setIsServiceModalOpen(true);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    interface Node {
      x: number;
      y: number;
      connections: number[];
      speed: number;
      angle: number;
      glowIntensity: number;
      glowDirection: number;
      nextGlowTime: number;
    }

    const nodes: Node[] = [];
    const nodeCount = 20; // Increased for more complex network visualization
    const connectionDistance = 350; // Increased for more connections

    // Create nodes with glow properties
    for (let i = 0; i < nodeCount; i++) {
      nodes.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        connections: [],
        speed: 0.08 + Math.random() * 0.12, // Slightly slower movement
        angle: Math.random() * Math.PI * 2,
        glowIntensity: 0,
        glowDirection: 1,
        nextGlowTime: Math.random() * 5000,
      });
    }

    // Initialize connections
    nodes.forEach((node, i) => {
      nodes.forEach((otherNode, j) => {
        if (i !== j) {
          const dx = node.x - otherNode.x;
          const dy = node.y - otherNode.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < connectionDistance && node.connections.length < 4) {
            // More connections per node
            node.connections.push(j);
          }
        }
      });
    });

    function drawConnection(
      x1: number,
      y1: number,
      x2: number,
      y2: number,
      progress: number,
      glowIntensity: number
    ) {
      if (!ctx) return;

      const dx = x2 - x1;
      const dy = y2 - y1;

      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2;
      const cpX = midX - dy * 0.5;
      const cpY = midY + dx * 0.5;

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.quadraticCurveTo(cpX, cpY, x2, y2);

      const gradient = ctx.createLinearGradient(x1, y1, x2, y2);
      const alpha = 0.1 + glowIntensity * 0.4;
      gradient.addColorStop(0, `rgba(59, 130, 246, ${alpha})`); // Adjusted blue color
      gradient.addColorStop(0.5, `rgba(59, 130, 246, ${alpha + 0.2})`);
      gradient.addColorStop(1, `rgba(59, 130, 246, ${alpha})`);

      ctx.strokeStyle = gradient;
      ctx.lineWidth = 2 + glowIntensity * 2;
      ctx.stroke();

      const packetPos = progress % 1;
      const t = packetPos;
      const packetX =
        (1 - t) * (1 - t) * x1 + 2 * (1 - t) * t * cpX + t * t * x2;
      const packetY =
        (1 - t) * (1 - t) * y1 + 2 * (1 - t) * t * cpY + t * t * y2;

      if (glowIntensity > 0) {
        ctx.beginPath();
        ctx.arc(packetX, packetY, 6 + glowIntensity * 4, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(96, 165, 250, ${glowIntensity * 0.3})`;
        ctx.fill();
      }

      ctx.beginPath();
      ctx.arc(packetX, packetY, 3, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(96, 165, 250, ${0.7 + glowIntensity * 0.3})`;
      ctx.fill();
    }

    let animationFrame: number;
    let lastTime = 0;
    const duration = 6000; // Slower animation

    function animate(currentTime: number) {
      if (!ctx || !canvas) return;

      const progress = (currentTime % duration) / duration;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      nodes.forEach((node) => {
        node.x += Math.cos(node.angle) * node.speed;
        node.y += Math.sin(node.angle) * node.speed;

        if (node.x < 0 || node.x > canvas.width)
          node.angle = Math.PI - node.angle;
        if (node.y < 0 || node.y > canvas.height) node.angle = -node.angle;

        if (currentTime >= node.nextGlowTime) {
          if (node.glowDirection > 0) {
            node.glowIntensity += 0.02;
            if (node.glowIntensity >= 1) {
              node.glowDirection = -1;
            }
          } else {
            node.glowIntensity -= 0.02;
            if (node.glowIntensity <= 0) {
              node.glowDirection = 1;
              node.nextGlowTime = currentTime + Math.random() * 5000;
              node.glowIntensity = 0;
            }
          }
        }

        if (node.glowIntensity > 0) {
          ctx.beginPath();
          ctx.arc(node.x, node.y, 6 + node.glowIntensity * 4, 0, Math.PI * 2);
          ctx.fillStyle = `rgba(59, 130, 246, ${node.glowIntensity * 0.3})`; // Adjusted blue color
          ctx.fill();
        }

        ctx.beginPath();
        ctx.arc(node.x, node.y, 4, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(59, 130, 246, ${0.7 + node.glowIntensity * 0.3})`; // Adjusted blue color
        ctx.fill();

        node.connections.forEach((connectionIndex) => {
          const connectedNode = nodes[connectionIndex];
          drawConnection(
            node.x,
            node.y,
            connectedNode.x,
            connectedNode.y,
            progress,
            Math.max(node.glowIntensity, connectedNode.glowIntensity)
          );
        });
      });

      lastTime = currentTime;
      animationFrame = requestAnimationFrame(animate);
    }

    animate(0);

    const handleResize = () => {
      if (!canvas) return;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    document.querySelectorAll(".fade-in-up, .scale-in").forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isModalOpen || isServiceModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isModalOpen, isServiceModalOpen]);

  return (
    <div className="min-h-screen bg-gray-900 text-white overflow-hidden">
      <canvas
        ref={canvasRef}
        className="fixed inset-0 pointer-events-none"
        style={{ zIndex: 0 }}
      />

      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80"
            alt="Global Technology Network"
            className="w-full h-full object-cover opacity-20"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-blue-900/50 to-gray-900/90" />
        </div>
        <div className="container mx-auto px-6 relative z-10">
          <div className="max-w-4xl fade-in-up">
            <div className="inline-block mb-4 px-4 py-2 bg-blue-500/20 rounded-full">
              <span className="text-blue-300">Global Enterprise Solutions</span>
            </div>
            <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              Transform Your Global Enterprise Infrastructure
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Enterprise-scale solutions with military-grade security, designed
              for organizations that operate on a global scale.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button
                onClick={handleConsultation}
                className="bg-blue-600 hover:bg-blue-700 px-8 py-4 rounded-lg font-semibold text-lg transition-all hover:scale-105 flex items-center gap-2"
              >
                Schedule Consultation
                <Building2 className="w-5 h-5" />
              </button>
              <button
                onClick={handleSolutions}
                className="bg-gray-800 hover:bg-gray-700 px-8 py-4 rounded-lg font-semibold text-lg transition-all hover:scale-105 flex items-center gap-2"
              >
                Enterprise Solutions
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative bg-gray-800/50 backdrop-blur-sm py-16">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {enterpriseStats.map((stat, index) => (
              <div
                key={index}
                className="text-center fade-in-up"
                style={{ transitionDelay: `${index * 200}ms` }}
              >
                <div className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
                  {stat.value}
                </div>
                <div className="text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Services Grid */}
      <div className="relative py-24">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1639322537228-f710d846310a?auto=format&fit=crop&q=80"
            alt="Enterprise Data Center"
            className="w-full h-full object-cover opacity-10"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/90 to-gray-900/90" />
        </div>
        <div className="container mx-auto px-6 relative z-10">
          <h2 className="text-4xl font-bold mb-16 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 fade-in-up">
            Global Enterprise Solutions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-xl border border-gray-700 hover:border-blue-500 transition-all group scale-in cursor-pointer"
                style={{ transitionDelay: `${index * 100}ms` }}
                onClick={() => openServiceModal(service)}
              >
                <div className="mb-6 p-4 bg-blue-500/10 rounded-lg inline-block group-hover:bg-blue-500/20 transition-all">
                  <service.icon className="w-8 h-8 text-blue-400" />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-gray-100 group-hover:text-blue-300 transition-colors">
                  {service.title}
                </h3>
                <p className="text-gray-400 text-lg">{service.description}</p>
                <div className="mt-6 flex items-center text-blue-400 opacity-0 group-hover:opacity-100 transition-opacity">
                  <span className="mr-2">Learn more</span>
                  <ExternalLink className="w-4 h-4" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="relative py-24 bg-gray-800/50 backdrop-blur-sm">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1550751827-4bd374c3f58b?auto=format&fit=crop&q=80"
            alt="Global Network"
            className="w-full h-full object-cover opacity-5"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-800/90 to-gray-800/90" />
        </div>
        <div className="container mx-auto px-6 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 fade-in-up">
                Enterprise-Scale Infrastructure
              </h2>
              <div className="grid grid-cols-1 gap-6">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-4 fade-in-up"
                    style={{ transitionDelay: `${index * 100}ms` }}
                  >
                    <div className="p-2 bg-blue-500/10 rounded-lg">
                      <CheckCircle2 className="w-6 h-6 text-blue-400" />
                    </div>
                    <span className="text-gray-300 text-lg">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-8">
              {benefits.map((benefit, index) => (
                <div
                  key={index}
                  className="bg-gray-700/50 backdrop-blur-sm p-8 rounded-xl border border-gray-600 hover:border-blue-500 transition-all scale-in"
                  style={{ transitionDelay: `${index * 200}ms` }}
                >
                  <div className="flex items-start gap-6">
                    <div className="p-3 bg-blue-500/10 rounded-lg">
                      <benefit.icon className="w-8 h-8 text-blue-400" />
                    </div>
                    <div>
                      <h3 className="text-2xl font-semibold mb-3 text-gray-100">
                        {benefit.title}
                      </h3>
                      <p className="text-gray-400 text-lg">
                        {benefit.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative py-24">
        <div className="container mx-auto px-6">
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-3xl p-16 relative overflow-hidden fade-in-up">
            <div className="absolute inset-0 bg-pattern opacity-10"></div>
            <div className="relative z-10 max-w-4xl mx-auto text-center">
              <h2 className="text-4xl font-bold mb-8">
                Ready to Transform Your Global Infrastructure?
              </h2>
              <p className="text-xl text-gray-200 mb-10">
                Connect with our enterprise solutions team to discover how we
                can elevate your global technology infrastructure.
              </p>
              <button
                onClick={handleGetStarted}
                className="bg-white text-blue-600 hover:bg-gray-100 px-10 py-4 rounded-lg font-semibold text-xl transition-all hover:scale-105 flex items-center gap-3 mx-auto"
              >
                Get Started
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Modal with EmailJS integration */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-gray-800 rounded-xl max-w-2xl w-full p-8 relative animate-fade-in">
            <button
              onClick={() => {
                setIsModalOpen(false);
                setFormData((prev) => ({ ...prev, message: "" }));
                setSubmitStatus({ type: "", message: "" });
              }}
              className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
            <h2 className="text-3xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              Enterprise Consultation
            </h2>

            {submitStatus.message && (
              <div
                className={`mb-6 p-4 rounded-lg ${
                  submitStatus.type === "success"
                    ? "bg-green-500/20 text-green-300"
                    : "bg-red-500/20 text-red-300"
                }`}
              >
                {submitStatus.message}
              </div>
            )}

            <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
              <input type="hidden" name="topic" value={selectedTopic} />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="John Doe"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    Business Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="john@enterprise.com"
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Organization Name
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={(e) =>
                    setFormData({ ...formData, company: e.target.value })
                  }
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                  placeholder="Enterprise Corporation"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                  rows={4}
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                  placeholder="Tell us about your enterprise IT needs..."
                  required
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full ${
                  isSubmitting ? "bg-blue-800" : "bg-blue-600 hover:bg-blue-700"
                } px-8 py-4 rounded-lg font-semibold text-lg transition-all hover:scale-105 flex items-center justify-center gap-2`}
              >
                {isSubmitting ? "Sending..." : "Submit Request"}
                <Send className="w-5 h-5" />
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Service Detail Modal */}
      {isServiceModalOpen && selectedService && (
        <div className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4 overflow-y-auto">
          <div className="bg-gray-800 rounded-xl max-w-5xl w-full my-8 relative animate-fade-in">
            <button
              onClick={() => {
                setIsServiceModalOpen(false);
                setSelectedService(null);
              }}
              className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors z-20"
            >
              <X className="w-6 h-6" />
            </button>

            <div className="relative h-64 md:h-80 overflow-hidden rounded-t-xl">
              <img
                src={selectedService.image}
                alt={selectedService.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent" />
              <div className="absolute bottom-0 left-0 p-8 w-full">
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-4 bg-blue-500/20 backdrop-blur-sm rounded-lg">
                    <selectedService.icon className="w-8 h-8 text-blue-300" />
                  </div>
                  <h2 className="text-3xl md:text-4xl font-bold text-white">
                    {selectedService.title}
                  </h2>
                </div>
              </div>
            </div>

            <div className="p-8">
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-blue-300">
                  Overview
                </h3>
                <p className="text-gray-300 text-lg leading-relaxed">
                  {selectedService.fullDescription}
                </p>
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-blue-300">
                  Key Features
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {selectedService.features.map((feature, index) => (
                    <div key={index} className="flex items-start gap-3">
                      <div className="mt-1">
                        <CheckCircle2 className="w-5 h-5 text-blue-400" />
                      </div>
                      <span className="text-gray-300">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gray-700/50 p-6 rounded-xl border border-gray-600">
                <h3 className="text-xl font-semibold mb-4 text-blue-300">
                  Ready to implement {selectedService.title}?
                </h3>
                <p className="text-gray-300 mb-6">
                  Our enterprise team is ready to discuss how our{" "}
                  {selectedService.title.toLowerCase()} solutions can be
                  tailored to your specific business requirements.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <button
                    onClick={() => {
                      setFormData((prev) => ({
                        ...prev,
                        message: `I'm interested in learning more about your ${selectedService.title} solutions.`,
                      }));
                      setIsServiceModalOpen(false);
                      setIsModalOpen(true);
                    }}
                    className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg font-semibold transition-all hover:scale-105 flex items-center justify-center gap-2"
                  >
                    Request Information
                    <Send className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => {
                      setIsServiceModalOpen(false);
                    }}
                    className="bg-gray-600 hover:bg-gray-500 px-6 py-3 rounded-lg font-semibold transition-all hover:scale-105"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
    </div>
  );
}

export default EnterpriseSolutions;
