import React, { useEffect, useRef, useState } from 'react';
import { 
  Shield, 
  Cloud,
  ChevronRight,
  CheckCircle2,
  Award,
  Cpu,
  Network,
  Lock,
  Code,
  Wifi,
  HardDrive,
  Send,
  X
} from 'lucide-react';

const services = [
  {
    icon: Shield,
    title: "Cybersecurity Solutions",
    description: "Enterprise-grade security with advanced threat detection, firewall management, and 24/7 monitoring to protect your business data."
  },
  {
    icon: Network,
    title: "Network Infrastructure",
    description: "Robust network design, implementation, and management. From secure WiFi to complex multi-site networks."
  },
  {
    icon: Cloud,
    title: "Cloud Services",
    description: "Seamless cloud migration, hybrid solutions, and cloud-native applications. AWS, Azure, and Google Cloud expertise."
  },
  {
    icon: HardDrive,
    title: "Data Management",
    description: "Comprehensive data solutions including backup, recovery, and storage optimization with enterprise-grade reliability."
  },
  {
    icon: Cpu,
    title: "Hardware Solutions",
    description: "Expert hardware procurement, deployment, and maintenance. From workstations to server infrastructure."
  },
  {
    icon: Code,
    title: "Software Integration",
    description: "Custom software solutions, system integration, and automation to streamline your business processes."
  }
];

const benefits = [
  {
    icon: Lock,
    title: "Enterprise Security",
    description: "Military-grade encryption and security protocols to protect your business assets."
  },
  {
    icon: Wifi,
    title: "99.99% Uptime",
    description: "Redundant systems and proactive monitoring ensure your business stays online."
  },
  {
    icon: Award,
    title: "Certified Experts",
    description: "Team of certified IT professionals with expertise across all major platforms."
  }
];

const features = [
  "24/7 Network Monitoring & Support",
  "Enterprise-Grade Security Solutions",
  "Disaster Recovery Planning",
  "Cloud Infrastructure Management",
  "Hardware & Software Procurement",
  "IT Strategy Consulting"
];

const techStats = [
  { value: "500+", label: "Servers Managed" },
  { value: "99.99%", label: "Network Uptime" },
  { value: "15+", label: "Security Certifications" }
];

function SmallBusinessSolutions() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  const handleSecurityAudit = () => {
    setFormData(prev => ({
      ...prev,
      message: "I'm interested in scheduling a security audit for my organization."
    }));
    setIsModalOpen(true);
  };

  const handleViewSolutions = () => {
    setFormData(prev => ({
      ...prev,
      message: "I'd like to learn more about your IT solutions and how they can benefit my business."
    }));
    setIsModalOpen(true);
  };

  const handleGetStarted = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({ name: '', email: '', company: '', message: '' });
    setIsModalOpen(false);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    interface Node {
      x: number;
      y: number;
      connections: number[];
      speed: number;
      angle: number;
      glowIntensity: number;
      glowDirection: number;
      nextGlowTime: number;
    }

    const nodes: Node[] = [];
    const nodeCount = 15;
    const connectionDistance = 300;

    // Create nodes with glow properties
    for (let i = 0; i < nodeCount; i++) {
      nodes.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        connections: [],
        speed: 0.1 + Math.random() * 0.15, // Reduced speed range
        angle: Math.random() * Math.PI * 2,
        glowIntensity: 0,
        glowDirection: 1,
        nextGlowTime: Math.random() * 5000 // Random start time for glow
      });
    }

    // Initialize connections
    nodes.forEach((node, i) => {
      nodes.forEach((otherNode, j) => {
        if (i !== j) {
          const dx = node.x - otherNode.x;
          const dy = node.y - otherNode.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < connectionDistance && node.connections.length < 3) {
            node.connections.push(j);
          }
        }
      });
    });

    function drawConnection(x1: number, y1: number, x2: number, y2: number, progress: number, glowIntensity: number) {
      if (!ctx) return;
      
      const dx = x2 - x1;
      const dy = y2 - y1;
      
      // Control points for the curve
      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2;
 
      const cpX = midX - dy * 0.5;
      const cpY = midY + dx * 0.5;

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.quadraticCurveTo(cpX, cpY, x2, y2);
      
      // Create gradient with glow effect
      const gradient = ctx.createLinearGradient(x1, y1, x2, y2);
      const alpha = 0.1 + (glowIntensity * 0.4); // Increase opacity based on glow
      gradient.addColorStop(0, `rgba(37, 99, 235, ${alpha})`);
      gradient.addColorStop(0.5, `rgba(37, 99, 235, ${alpha + 0.2})`);
      gradient.addColorStop(1, `rgba(37, 99, 235, ${alpha})`);
      
      ctx.strokeStyle = gradient;
      ctx.lineWidth = 2 + (glowIntensity * 2); // Increase line width based on glow
      ctx.stroke();

      // Draw data packet with glow
      const packetPos = progress % 1;
      const t = packetPos;
      const packetX = (1 - t) * (1 - t) * x1 + 2 * (1 - t) * t * cpX + t * t * x2;
      const packetY = (1 - t) * (1 - t) * y1 + 2 * (1 - t) * t * cpY + t * t * y2;

      // Draw glow
      if (glowIntensity > 0) {
        ctx.beginPath();
        ctx.arc(packetX, packetY, 6 + (glowIntensity * 4), 0, Math.PI * 2);
        ctx.fillStyle = `rgba(96, 165, 250, ${glowIntensity * 0.3})`;
        ctx.fill();
      }

      // Draw packet
      ctx.beginPath();
      ctx.arc(packetX, packetY, 3, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(96, 165, 250, ${0.7 + (glowIntensity * 0.3)})`;
      ctx.fill();
    }

    let animationFrame: number;
    let lastTime = 0;
    const duration = 5000; // Increased duration for slower movement

    function animate(currentTime: number) {
      if (!ctx || !canvas) return;
      
      const progress = (currentTime % duration) / duration;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Update node positions and glow effects
      nodes.forEach(node => {
        // Update position more slowly
        node.x += Math.cos(node.angle) * node.speed;
        node.y += Math.sin(node.angle) * node.speed;

        // Bounce off walls
        if (node.x < 0 || node.x > canvas.width) node.angle = Math.PI - node.angle;
        if (node.y < 0 || node.y > canvas.height) node.angle = -node.angle;

        // Update glow effect
        if (currentTime >= node.nextGlowTime) {
          if (node.glowDirection > 0) {
            node.glowIntensity += 0.02;
            if (node.glowIntensity >= 1) {
              node.glowDirection = -1;
            }
          } else {
            node.glowIntensity -= 0.02;
            if (node.glowIntensity <= 0) {
              node.glowDirection = 1;
              node.nextGlowTime = currentTime + Math.random() * 5000; // Set next glow time
              node.glowIntensity = 0;
            }
          }
        }

        // Draw node with glow
        if (node.glowIntensity > 0) {
          ctx.beginPath();
          ctx.arc(node.x, node.y, 6 + (node.glowIntensity * 4), 0, Math.PI * 2);
          ctx.fillStyle = `rgba(37, 99, 235, ${node.glowIntensity * 0.3})`;
          ctx.fill();
        }

        ctx.beginPath();
        ctx.arc(node.x, node.y, 4, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(37, 99, 235, ${0.7 + (node.glowIntensity * 0.3)})`;
        ctx.fill();

        // Draw connections with glow
        node.connections.forEach(connectionIndex => {
          const connectedNode = nodes[connectionIndex];
          drawConnection(
            node.x, 
            node.y, 
            connectedNode.x, 
            connectedNode.y, 
            progress,
            Math.max(node.glowIntensity, connectedNode.glowIntensity)
          );
        });
      });

      lastTime = currentTime;
      animationFrame = requestAnimationFrame(animate);
    }

    animate(0);

    const handleResize = () => {
      if (!canvas) return;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    document.querySelectorAll('.fade-in-up, .scale-in').forEach(element => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModalOpen]);

  return (
    <div className="min-h-screen bg-gray-900 text-white overflow-hidden">
      <canvas
        ref={canvasRef}
        className="fixed inset-0 pointer-events-none"
        style={{ zIndex: 0 }}
      />

      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1588508065123-287b28e013da?auto=format&fit=crop&q=80"
            alt="Modern Data Center"
            className="w-full h-full object-cover opacity-20"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-blue-900/50 to-gray-900/90" />
        </div>
        <div className="container mx-auto px-6 relative z-10">
          <div className="max-w-3xl fade-in-up">
            <div className="inline-block mb-4 px-4 py-2 bg-blue-500/20 rounded-full">
              <span className="text-blue-300">Enterprise IT Solutions</span>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              Next-Gen IT Infrastructure for Modern Business
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Enterprise-grade IT solutions with military-level security, designed for businesses that demand excellence.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button 
                onClick={handleSecurityAudit}
                className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-lg font-semibold text-lg transition-all hover:scale-105 flex items-center gap-2"
              >
                Schedule Security Audit
                <Shield className="w-5 h-5" />
              </button>
              <button 
                onClick={handleViewSolutions}
                className="bg-gray-800 hover:bg-gray-700 px-8 py-3 rounded-lg font-semibold text-lg transition-all hover:scale-105 flex items-center gap-2"
              >
                View Solutions
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative bg-gray-800 py-12">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {techStats.map((stat, index) => (
              <div key={index} className="text-center fade-in-up" style={{ transitionDelay: `${index * 200}ms` }}>
                <div className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
                  {stat.value}
                </div>
                <div className="text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Services Grid */}
      <div className="relative py-20">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1597733336794-12d05021d510?auto=format&fit=crop&q=80"
            alt="Server Room"
            className="w-full h-full object-cover opacity-10"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/90 to-gray-900/90" />
        </div>
        <div className="container mx-auto px-6 relative z-10">
          <h2 className="text-3xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 fade-in-up">
            Enterprise IT Solutions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div 
                key={index}
                className="bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700 hover:border-blue-500 transition-all group scale-in"
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <div className="mb-4 p-3 bg-blue-500/10 rounded-lg inline-block group-hover:bg-blue-500/20 transition-all">
                  <service.icon className="w-6 h-6 text-blue-400" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-100">{service.title}</h3>
                <p className="text-gray-400">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="relative py-20 bg-gray-800">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1520869562399-e772f042f422?auto=format&fit=crop&q=80"
            alt="Network Infrastructure"
            className="w-full h-full object-cover opacity-5"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-800/90 to-gray-800/90" />
        </div>
        <div className="container mx-auto px-6 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 fade-in-up">
                Enterprise-Grade Infrastructure
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-center gap-3 fade-in-up" style={{ transitionDelay: `${index * 100}ms` }}>
                    <div className="p-1 bg-blue-500/10 rounded">
                      <CheckCircle2 className="w-5 h-5 text-blue-400" />
                    </div>
                    <span className="text-gray-300">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6">
              {benefits.map((benefit, index) => (
                <div 
                  key={index}
                  className="bg-gray-700/50 backdrop-blur-sm p-6 rounded-xl border border-gray-600 hover:border-blue-500 transition-all scale-in"
                  style={{ transitionDelay: `${index * 200}ms` }}
                >
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-blue-500/10 rounded-lg">
                      <benefit.icon className="w-6 h-6 text-blue-400" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2 text-gray-100">{benefit.title}</h3>
                      <p className="text-gray-400">{benefit.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative py-20">
        <div className="container mx-auto px-6">
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-2xl p-12 relative overflow-hidden fade-in-up">
            <div className="absolute inset-0 bg-pattern opacity-10"></div>
            <div className="relative z-10 max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6">Ready to Transform Your IT Infrastructure?</h2>
              <p className="text-xl text-gray-200 mb-8">
                Schedule a consultation with our security experts and discover how we can protect and optimize your business technology.
              </p>
              <button 
                onClick={handleGetStarted}
                className="bg-white text-blue-600 hover:bg-gray-100 px-8 py-3 rounded-lg font-semibold text-lg transition-all hover:scale-105 flex items-center gap-2 mx-auto"
              >
                Get Started
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-gray-800 rounded-xl max-w-2xl w-full p-6 relative animate-fade-in">
            <button 
              onClick={() => {
                setIsModalOpen(false);
                setFormData(prev => ({ ...prev, message: '' }));
              }}
              className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
            <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              Let's Get Started
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="John Doe"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                    placeholder="john@company.com"
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-2">
                  Company Name
                </label>
                <input
                  type="text"
                  id="company"
                  value={formData.company}
                  onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                  placeholder="Company Ltd."
                  required
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  rows={4}
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                  placeholder="Tell us about your IT needs..."
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700 px-8 py-4 rounded-lg font-semibold text-lg transition-all hover:scale-105 flex items-center justify-center gap-2"
              >
                Send Message
                <Send className="w-5 h-5" />
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Footer */}

    </div>
  );
}

export default SmallBusinessSolutions;