import React, { useRef, useState, FormEvent } from "react";
import emailjs from "@emailjs/browser";
import {
  Shield,
  Cloud,
  Phone,
  Mail,
  Server,
  Lock,
  HardDrive,
  Headphones,
  Star,
  Code2,
  ChevronRight,
  Menu,
  X,
} from "lucide-react";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";
function UptimizeWebsite() {
  // EmailJS form handling
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    select: "",
    jobTitle: "",
  });
  const serviceOptions = [
    { value: "Managed IT Services", label: "Managed IT Services" },
    { value: "Cybersecurity", label: "Cybersecurity" },
    { value: "Cloud Solutions", label: "Cloud Solutions" },
    { value: "Business Phone Systems", label: "Business Phone Systems (VoIP)" },
    { value: "Data Backup and Recovery", label: "Data Backup and Recovery" },
    { value: "Support", label: "On-Site and Remote Support" },
  ];
  const handleDropdownChange = (option: Option) => {
    setFormData((prev) => ({
      ...prev,
      select: option.value,
    }));
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: "", message: "" });

    try {
      const result = await emailjs.sendForm(
        "service_3lmw01i",
        "template_m2pdq29",
        formRef.current!,
        "0wWYx_0TTQciXgTPV"
      );

      if (result.text === "OK") {
        setSubmitStatus({
          type: "success",
          message: "Message sent successfully! We will contact you soon.",
        });
        formRef.current?.reset();
        setFormData({
          name: "",
          email: "",
          company: "",
          select: "",
          jobTitle: "",
        });
      }
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to send message. Please try again.",
      });
      console.error("EmailJS Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-900 to-navy-800">
      {/* Navigation */}
      <nav className="bg-navy-900/80 backdrop-blur-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Desktop Navigation */}
          <div className="hidden md:flex justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0 flex items-center">
                <span className="text-blue-400 font-bold text-xl">
                  UPTIMIZE CONSULTING
                </span>
              </div>
            </div>

            <div className="flex items-center space-x-8">
              <a
                href="#about"
                className="text-gray-300 hover:text-blue-400 transition-colors"
              >
                About
              </a>
              <a
                href="#services"
                className="text-gray-300 hover:text-blue-400 transition-colors"
              >
                Services
              </a>
              <a
                href="#testimonials"
                className="text-gray-300 hover:text-blue-400 transition-colors"
              >
                Testimonials
              </a>
              <a
                href="#contact"
                className="text-gray-300 hover:text-blue-400 transition-colors"
              >
                Contact
              </a>
              <a
                href="/Enterprisesolutions"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-medium py-2 px-5 rounded-lg transition-all duration-300 shadow-md hover:shadow-lg transform hover:scale-105 flex items-center gap-2 border border-blue-500/30"
              >
                <span className="text-white">Enterprise Solutions</span>
                <ChevronRight className="w-4 h-4 text-white" />
              </a>
            </div>
          </div>

          {/* Mobile Navigation */}
          <div className="md:hidden flex flex-col">
            {/* Logo centered at top */}
            <div className="flex justify-center py-4">
              <div className="flex-shrink-0">
                <span className="text-blue-400 font-bold text-xl">
                  UPTIMIZE CONSULTING
                </span>
              </div>
            </div>

            {/* Mobile navigation buttons below logo */}
            <div className="flex justify-between items-center py-2">
              <button
                onClick={toggleMobileMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-navy-800"
              >
                {mobileMenuOpen ? (
                  <X className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="h-6 w-6" aria-hidden="true" />
                )}
              </button>

              <a
                href="/Enterprisesolutions"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-medium py-2 px-4 rounded-lg transition-all duration-300 shadow-md hover:shadow-lg transform hover:scale-105 flex items-center gap-1 border border-blue-500/30"
              >
                <span className="text-white">Enterprise Solutions</span>
                <ChevronRight className="w-3 h-3 text-white" />
              </a>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-navy-900/95 backdrop-blur-lg border-t border-gray-800">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a
                href="#about"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-navy-800"
                onClick={() => setMobileMenuOpen(false)}
              >
                About
              </a>
              <a
                href="#services"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-navy-800"
                onClick={() => setMobileMenuOpen(false)}
              >
                Services
              </a>
              <a
                href="#testimonials"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-navy-800"
                onClick={() => setMobileMenuOpen(false)}
              >
                Testimonials
              </a>
              <a
                href="#contact"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-navy-800"
                onClick={() => setMobileMenuOpen(false)}
              >
                Contact
              </a>
            </div>
          </div>
        )}
      </nav>

      {/* Hero Banner */}
      <div className="relative overflow-hidden">
        {/* Background Elements */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:60px_60px]" />
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10" />
          <div className="absolute right-0 top-0 -translate-y-1/4 translate-x-1/4 w-[600px] h-[600px] rounded-full bg-gradient-to-br from-blue-500/30 to-purple-500/30 blur-3xl" />
        </div>

        {/* Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 relative">
          <div className="pt-20 pb-24">
            {/* Feature Icons */}
            <div className="flex justify-center gap-8 mb-12">
              {[
                { icon: <Shield size={28} />, label: "Security" },
                { icon: <Server size={28} />, label: "Cloud" },
                { icon: <Code2 size={28} />, label: "Development" },
              ].map((item, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div className="bg-gradient-to-br from-blue-500/20 to-purple-500/20 p-4 rounded-lg backdrop-blur-sm mb-2">
                    <div className="text-blue-400">{item.icon}</div>
                  </div>
                  <span className="text-gray-300 text-sm">{item.label}</span>
                </div>
              ))}
            </div>

            {/* Main Content */}
            <div className="text-center max-w-4xl mx-auto">
              <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 leading-tight">
                IT Solutions for Small Businesses in Naperville & Surrounding
                Areas
              </h1>
              <h2 className="text-3xl font-bold mb-6">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                  Reliable, proactive IT support tailored to your unique needs
                  and budget
                </span>
              </h2>
              <div className="flex flex-col sm:flex-row justify-center gap-4 mt-10">
                <button
                  onClick={scrollToContact}
                  className="bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 transition-all"
                >
                  Get a Free IT Consultation
                </button>
                <button className="bg-transparent border-2 border-blue-400 text-blue-400 font-semibold py-3 px-6 rounded-lg hover:bg-blue-400/10 transition-colors">
                  Explore Our Services
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Gradient */}
        <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-navy-800 to-transparent" />
      </div>

      {/* About Section */}
      <section id="about" className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Defining Small Business
            </h2>
            <p className="text-lg text-gray-300 leading-relaxed">
              Uptimize Consulting understands that "small business" isn't
              one-size-fits-all. We support the diverse needs of small
              businesses in Naperville and surrounding area, from
              micro-businesses and home offices to growing companies with
              multiple locations. Whether you're a sole proprietor needing
              reliable WiFi and basic cybersecurity, an SMB requiring robust
              network management and cloud solutions, or a rapidly growing
              business needing scalable IT infrastructure, we have the expertise
              to help you thrive.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">
              Our IT Solutions
            </h2>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto">
              We provide comprehensive IT solutions designed specifically for
              small businesses like yours. We offer proactive support,
              cybersecurity protection, cloud solutions, and more, all at a
              price that makes sense for your business.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Managed IT Services */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-all hover:shadow-lg hover:shadow-blue-500/5">
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-lg flex items-center justify-center mb-4">
                <Server className="text-blue-400" size={24} />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                Managed IT Services
              </h3>
              <p className="text-gray-300 mb-4">
                Proactive monitoring, maintenance, and support to minimize
                downtime and maximize productivity. Scalable solutions for
                businesses of all sizes, from essential support for home offices
                to comprehensive IT management for multi-location SMBs.
              </p>
              <p className="text-gray-400">
                Our networking solutions include LAN/WAN setup and management,
                reliable Internet connectivity, secure Wireless networks, and
                cellular backup solutions. We work with industry-leading vendors
                like Cisco Meraki, Ubiquiti, and SonicWall to deliver
                enterprise-grade networking for small businesses.
              </p>
            </div>

            {/* Cybersecurity */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-all hover:shadow-lg hover:shadow-blue-500/5">
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-lg flex items-center justify-center mb-4">
                <Lock className="text-blue-400" size={24} />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                Cybersecurity
              </h3>
              <p className="text-gray-300 mb-4">
                Protect your business from cyber threats with our comprehensive
                security solutions. Tiered options available, from basic
                protection for home offices to advanced/enterprise consulting
                for growing businesses.
              </p>
              <p className="text-gray-400">
                Our cybersecurity stack includes advanced Antivirus protection,
                Next-Gen Firewalls, Security Information and Event Management
                (SIEM), email security, and employee security awareness
                training. We partner with leading security providers like
                Fortinet, Sophos, and Bitdefender to keep your business
                protected from evolving threats.
              </p>
            </div>

            {/* Cloud Solutions */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-all hover:shadow-lg hover:shadow-blue-500/5">
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-lg flex items-center justify-center mb-4">
                <Cloud className="text-blue-400" size={24} />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                Cloud Solutions
              </h3>
              <p className="text-gray-300 mb-4">
                Migrate to the cloud seamlessly and leverage the power of cloud
                computing. Solutions tailored to your needs, whether you're a
                single user or have a distributed team.
              </p>
              <p className="text-gray-400">
                We offer comprehensive cloud services including Microsoft 365,
                Google Workspace, AWS, and Azure solutions. Our cloud and data
                center solutions provide reliable remote backup and business
                continuity options to ensure your critical data is always
                available, even during disasters.
              </p>
            </div>

            {/* Business Phone Systems */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-all hover:shadow-lg hover:shadow-blue-500/5">
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-lg flex items-center justify-center mb-4">
                <Phone className="text-blue-400" size={24} />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                Business Phone Systems (VoIP)
              </h3>
              <p className="text-gray-300 mb-4">
                Reliable and cost-effective VoIP solutions to improve your
                communications.
              </p>
              <p className="text-gray-400">
                We implement and support leading VoIP platforms including 8x8,
                RingCentral, Microsoft Teams Phone, and Zoom Phone. Our VoIP
                solutions provide enterprise-grade features at small business
                prices, with flexible options to match your specific
                communication needs.
              </p>
            </div>

            {/* Data Backup and Recovery */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-all hover:shadow-lg hover:shadow-blue-500/5">
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-lg flex items-center justify-center mb-4">
                <HardDrive className="text-blue-400" size={24} />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                Data Backup and Recovery
              </h3>
              <p className="text-gray-300 mb-4">
                Ensure your critical data is safe and recoverable in case of
                disaster.
              </p>
              <p className="text-gray-400">
                Our storage and backup solutions include NAS (Network Attached
                Storage), SAN (Storage Area Network), and hybrid cloud options.
                We work with trusted vendors like Synology, QNAP, and Datto to
                provide reliable data storage and automated backup systems with
                quick recovery capabilities.
              </p>
            </div>

            {/* On-Site and Remote Support */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20 hover:border-blue-500/40 transition-all hover:shadow-lg hover:shadow-blue-500/5">
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-lg flex items-center justify-center mb-4">
                <Headphones className="text-blue-400" size={24} />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                On-Site and Remote Support
              </h3>
              <p className="text-gray-300 mb-4">
                Fast and reliable support whenever you need it.
              </p>
              <p className="text-gray-400">
                Our technical team uses industry-leading remote monitoring and
                management tools from ConnectWise, Datto RMM, and TeamViewer to
                provide proactive support. For Naperville and surrounding areas,
                we offer rapid on-site response when hands-on assistance is
                required.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">Testimonials</h2>
            <p className="text-lg text-gray-300">
              See what our small business clients have to say about our IT
              services.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {/* Testimonial 1 */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20">
              <div className="flex items-center mb-4">
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
              </div>
              <p className="text-gray-300 italic mb-6">
                "Uptimize Consulting has transformed our grocery store's
                technology infrastructure. Their proactive approach to IT
                management has eliminated downtime and improved our checkout
                systems, allowing us to provide better service to our
                customers."
              </p>
              <div>
                <p className="font-semibold text-white">Nir Mor</p>
                <p className="text-gray-400 text-sm">Naperville Fresh Market</p>
              </div>
            </div>

            {/* Testimonial 2 */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20">
              <div className="flex items-center mb-4">
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
              </div>
              <p className="text-gray-300 italic mb-6">
                "Our garage door business relies on efficient scheduling and
                customer management systems. Uptimize Consulting has provided
                exceptional IT support that keeps our operations running
                smoothly and ensures we can provide timely service to our
                customers."
              </p>
              <div>
                <p className="font-semibold text-white">Steve A</p>
                <p className="text-gray-400 text-sm">MG Garage Door</p>
              </div>
            </div>

            {/* Testimonial 3 */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20">
              <div className="flex items-center mb-4">
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
                <Star className="text-yellow-400" size={20} />
              </div>
              <p className="text-gray-300 italic mb-6">
                "I was struggling with constant WiFi connectivity issues in my
                home office that were affecting my productivity. The team at
                Uptimize Consulting quickly diagnosed the problem and
                implemented a solution that has completely resolved the issues.
                I can now work without interruptions."
              </p>
              <div>
                <p className="font-semibold text-white">Carlos B</p>
                <p className="text-gray-400 text-sm">
                  Home Office Professional
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section with EmailJS integration */}
      <section id="contact" className="py-20 border-t border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">Contact Us</h2>
            <p className="text-lg text-gray-300">
              Get in touch with our team to discuss your IT needs.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            {/* Contact Information */}
            <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-8">
              <div className="space-y-6 mb-8">
                <div className="flex items-start space-x-4">
                  <div className="bg-blue-500/20 p-3 rounded-lg">
                    <Mail className="text-blue-400" size={24} />
                  </div>
                  <div>
                    <h3 className="text-white font-semibold mb-1">Email</h3>
                    <p className="text-gray-300">
                      support@uptimizeconsulting.com
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-purple-500/20 p-3 rounded-lg">
                    <Phone className="text-purple-400" size={24} />
                  </div>
                  <div>
                    <h3 className="text-white font-semibold mb-1">Phone</h3>
                    <p className="text-gray-300">(630) 445-1958</p>
                  </div>
                </div>
              </div>

              {submitStatus.message && (
                <div
                  className={`mb-6 p-4 rounded-lg ${
                    submitStatus.type === "success"
                      ? "bg-green-500/20 text-green-300"
                      : "bg-red-500/20 text-red-300"
                  }`}
                >
                  {submitStatus.message}
                </div>
              )}

              <form ref={formRef} onSubmit={sendEmail} className="mt-8">
                <input
                  type="hidden"
                  name="topic"
                  value="Website Contact Form"
                />
                <div className="space-y-4">
                  <div>
                    <label htmlFor="name" className="block text-gray-300 mb-2">
                      Your Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 bg-navy-900/80 border border-gray-700 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-white"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-gray-300 mb-2">
                      Your Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 bg-navy-900/80 border border-gray-700 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-white"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="company"
                      className="block text-gray-300 mb-2"
                    >
                      Company
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 bg-navy-900/80 border border-gray-700 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-white"
                      placeholder="Your Company"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="select"
                      className="block text-gray-300 mb-2 font-medium"
                    >
                      Service Interest
                    </label>
                    <Dropdown
                      options={serviceOptions}
                      onChange={handleDropdownChange}
                      value={
                        formData.select
                          ? { value: formData.select, label: formData.select }
                          : undefined
                      }
                      placeholder="Select a service"
                      className="react-dropdown-container"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="jobTitle"
                      className="block text-gray-300 mb-2"
                    >
                      Job Title
                    </label>
                    <input
                      type="text"
                      id="jobTitle"
                      name="jobTitle"
                      value={formData.jobTitle}
                      onChange={handleInputChange}
                      // Continuation of the form input fields
                      className="w-full px-4 py-2 bg-navy-900/80 border border-gray-700 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-white"
                      placeholder="Your Job Title"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full ${
                      isSubmitting
                        ? "bg-blue-800"
                        : "bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700"
                    } text-white font-semibold py-3 px-6 rounded-lg transition-all`}
                  >
                    {isSubmitting ? "Sending..." : "Request a Quote"}
                  </button>
                </div>
              </form>
            </div>

            {/* Map */}
            <div className="rounded-xl overflow-hidden shadow-lg h-[400px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47518.58691507707!2d-88.17914442089842!3d41.75083957713038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e5761e216ce59%3A0x36c3e5891e9a2f31!2sNaperville%2C%20IL!5e0!3m2!1sen!2sus!4v1716825000000!5m2!1sen!2sus"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Naperville Map"
                className=" contrast-125 opacity-80"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UptimizeWebsite;
