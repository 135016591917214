export interface BlogPost {
  id: string;
  title: string;
  date: string;
  image: string;
  category: string;
  excerpt: string;
  content: Array<{
    type: "paragraph" | "heading" | "list" | "code";
    content?: string;
    language?: string;
    items?: string[];
  }>;
}

export const blogPosts: BlogPost[] = [
  {
    id: "cloud-migration-strategies-2024",
    title: "Cloud Migration Strategies for 2024",
    date: "2024-01-15",
    category: "Cloud Computing",
    image: "https://images.unsplash.com/photo-1544197150-b99a580bb7a8",
    excerpt:
      "A comprehensive guide to modern cloud migration strategies for enterprises in 2024.",
    content: [
      {
        type: "paragraph",
        content:
          "As we move into 2024, cloud migration continues to be a critical focus for organizations looking to modernize their infrastructure and improve scalability. This guide explores the latest strategies and best practices for successful cloud migration.",
      },
      {
        type: "heading",
        content: "Key Migration Strategies",
      },
      {
        type: "list",
        items: [
          "Rehosting (Lift and Shift)",
          "Replatforming (Lift, Tinker and Shift)",
          "Refactoring/Re-architecting",
          "Hybrid Approaches",
        ],
      },
      {
        type: "heading",
        content: "Implementation Example",
      },
      {
        type: "code",
        language: "typescript",
        content: `
  // Cloud Migration Configuration
  interface MigrationConfig {
    sourceEnv: string;
    targetCloud: 'AWS' | 'Azure' | 'GCP';
    strategy: 'rehost' | 'replatform' | 'refactor';
    services: string[];
  }
  
  const migrationPlan: MigrationConfig = {
    sourceEnv: 'on-premise',
    targetCloud: 'AWS',
    strategy: 'replatform',
    services: ['web-apps', 'databases', 'storage']
  };`,
      },
    ],
  },
  {
    id: "cybersecurity-best-practices",
    title: "Essential Cybersecurity Best Practices",
    date: "2024-01-10",
    category: "Security",
    image: "https://images.unsplash.com/photo-1550751827-4bd374c3f58b",
    excerpt:
      "Learn about critical cybersecurity practices that every IT professional should implement.",
    content: [
      {
        type: "paragraph",
        content:
          "In today's digital landscape, robust cybersecurity measures are non-negotiable. This guide covers essential practices to protect your organization's digital assets.",
      },
      {
        type: "heading",
        content: "Core Security Measures",
      },
      {
        type: "list",
        items: [
          "Multi-Factor Authentication (MFA)",
          "Zero Trust Architecture",
          "Regular Security Audits",
          "Employee Training Programs",
        ],
      },
      {
        type: "code",
        language: "typescript",
        content: `
  // Security Policy Implementation
  interface SecurityPolicy {
    mfaRequired: boolean;
    passwordPolicy: {
      minLength: number;
      requireSpecialChars: boolean;
      expirationDays: number;
    };
    accessControls: string[];
  }
  
  const securityConfig: SecurityPolicy = {
    mfaRequired: true,
    passwordPolicy: {
      minLength: 12,
      requireSpecialChars: true,
      expirationDays: 90
    },
    accessControls: ['role-based', 'least-privilege']
  };`,
      },
    ],
  },
  {
    id: "devops-automation-guide",
    title: "DevOps Automation Guide 2024",
    date: "2024-01-05",
    category: "DevOps",
    image: "https://images.unsplash.com/photo-1618401471353-b98afee0b2eb",
    excerpt:
      "Comprehensive guide to DevOps automation tools and practices for modern IT teams.",
    content: [
      {
        type: "paragraph",
        content:
          "DevOps automation is revolutionizing how teams deploy and manage infrastructure. This guide explores the latest tools and methodologies for effective DevOps implementation.",
      },
      {
        type: "heading",
        content: "Automation Essentials",
      },
      {
        type: "list",
        items: [
          "CI/CD Pipeline Automation",
          "Infrastructure as Code",
          "Automated Testing",
          "Monitoring and Alerting",
        ],
      },
      {
        type: "code",
        language: "yaml",
        content: `
  # CI/CD Pipeline Configuration
  name: Deploy Application
  on:
    push:
      branches: [ main ]
  jobs:
    build:
      runs-on: ubuntu-latest
      steps:
        - uses: actions/checkout@v2
        - name: Build and Test
          run: |
            npm install
            npm test
            npm run build`,
      },
    ],
  },
];
