import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">About Us</h3>
            <p className="text-gray-400">
              Leading IT solutions provider specializing in enterprise
              infrastructure, cybersecurity, and digital transformation.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/"
                  className="text-gray-400 hover:text-blue-400 transition-colors"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/SmallBusinessSolutions"
                  className="text-gray-400 hover:text-blue-400 transition-colors"
                >
                  Small Business
                </Link>
              </li>
              <li>
                <Link
                  to="/Enterprisesolutions"
                  className="text-gray-400 hover:text-blue-400 transition-colors"
                >
                  Enterprise
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="text-gray-400 hover:text-blue-400 transition-colors"
                >
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Services</h3>
            <ul className="space-y-2">
              <li className="text-gray-400">Cybersecurity</li>
              <li className="text-gray-400">Cloud Solutions</li>
              <li className="text-gray-400">IT Infrastructure</li>
              <li className="text-gray-400">Digital Transformation</li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="text-gray-400">Email: contact@uptimizeconsulting.com</li>
              <li className="text-gray-400">Phone: (630) 445-1958</li>
              <li className="text-gray-400">Location: Naperville, IL</li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-8 pt-8 text-center">
          <p className="text-gray-400">
            © {new Date().getFullYear()} Uptimize Consulting. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
